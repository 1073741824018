import './App.css';
import { ReactTyped } from "react-typed";
import { useState, useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";

function App() {
  const [isFinished, setIsFinished] = useState(false);
  const elem0 = useRef(null);
  const elem1 = useRef(null);
  useLayoutEffect(() => {
    let from = gsap.from(elem0.current, {
      opacity: 0,
      duration: 3,
      immediateRender: false
    });

    let to = gsap.to(elem0.current, {
      opacity: 1,
      delay: 3
    });

    return () => {
      from.kill();
      to.kill();
    };
  });

  useLayoutEffect(() => {
    let from = gsap.from(elem1.current, {
      opacity: 1,
      duration: 3,
      immediateRender: false
    });

    let to = gsap.to(elem1.current, {
      opacity: 0,
      delay: 3
    });

    return () => {
      from.kill();
      to.kill();
    };
  });

  return (
    <div className="App">
      <header className="App-header">
        {isFinished ? (
          <iframe ref={elem0} frameborder="0" title="itch.io" src="https://itch.io/embed/3122525?linkback=true&amp;border_width=2&amp;bg_color=0a0a0a&amp;fg_color=008b00&amp;link_color=004b00&amp;border_color=4a4a4a" width="554" height="169"><a href="https://beneggy.itch.io/the-system-explorer">The System Explorer by beneggy</a></iframe>
        ) : (
          <p ref={elem1}>
            &gt; {" "}
            <ReactTyped
              strings={["the system explorer"]}
              startDelay={100}
              typeSpeed={100}
              backSpeed={100}
              cursorChar="█"
              fadeOut={true}
              fadeOutDelay={5000}
              fadeOutClass='fade-out-now'
              showCursor={true}
              onComplete={ () => setIsFinished(true) }
            />
         </p>
        )
      }
      </header>
    </div>
  );
}

export default App;
